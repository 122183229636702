<template>
    <div>
        <el-form :inline="true" v-if="hasAuth('sys:evaluation:add')">
            <el-form-item>
                <el-button type="primary" @click="saveButton(null)"> 新增</el-button>
            </el-form-item>
        </el-form>
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" border stripe>
            <el-table-column prop="evaluationLabel" align="center" label="评价标签内容">
            </el-table-column>
            <el-table-column prop="evaluationLabelType" align="center" label="评价标签类型">
                <template slot-scope="{row}">
                    <span v-if="row.evaluationLabelType == 1">货主</span>
                    <span v-if="row.evaluationLabelType == 2">司机</span>
                </template>
            </el-table-column>
            <el-table-column prop="icon" align="center" width="300" label="操作">
                <template slot-scope="scope">
                    <el-button type="primary" plain v-if="hasAuth('sys:evaluation:update')"
                        @click="saveButton(scope.row.evaluationLabelId)">编辑</el-button>
                    <template v-if="hasAuth('sys:evaluation:delete')">
                        <el-popconfirm title="确定要删除该条数据吗？" @confirm="delHandle(scope.row.evaluationLabelId)">
                            <el-button type="danger" plain slot="reference">删除</el-button>
                        </el-popconfirm>
                    </template>
                </template>
            </el-table-column>
        </el-table>
        <saveEvaluationLabel ref="saveEvaluationLabel" v-if="saveEvaluationLabelvisible"
            @refreshDataList="getCustomerservice"></saveEvaluationLabel>
    </div>
</template>

<script>
import saveEvaluationLabel from "./saveEvaluationLabel";
export default {
    name: "VehicletypeList",
    components: {
        saveEvaluationLabel
    },
    data() {
        return {
            tableData: [],
            saveEvaluationLabelvisible: false,
        }
    },
    created() {
        this.getCustomerservice();
    },
    methods: {
        getCustomerservice() {
            this.$axios.post("/admin/evaluationlabel/getEvaluationLabelList").then(res => {
                this.tableData = res.data
            })
        },
        delHandle(id) {
            this.$axios.post("/admin/evaluationlabel/delEvaluationLabel", { evaluationLabelId: id }).then(res => {
                this.$message({
                    showClose: true,
                    message: '恭喜你，操作成功',
                    type: 'success',
                    onClose: () => {
                        this.getCustomerservice()
                    }
                });
            })
        },
        saveButton(evaluationLabelId) {
            this.saveEvaluationLabelvisible = true;
            this.$nextTick(() => {
                this.$refs.saveEvaluationLabel.init(evaluationLabelId);
            })
        },
    }
}
</script>

<style scoped>
.el-button {
    padding: 0 10px;
    height: 35px;
    line-height: 35px;
    font-size: 15px;
    margin-right: 10px;
}
</style>

